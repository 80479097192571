import { z } from 'zod';
import { PostReportMode, UnionOfKeys } from '@/shared/types';
import { CampaignReportOrderField } from '@/graphql';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { LIMIT_50 } from '@/shared/constants';

export const PostReportSortSchema = FilterValidations.sort(CampaignReportOrderField);
export const PostReportFilterSchema = z.object({
  keyword: z.string().optional(),
  mode: z.nativeEnum(PostReportMode).default(PostReportMode.GRID),
  endDate: z.string().optional(),
  startDate: z.string().optional()
});
export const PostReportSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(PostReportFilterSchema, {
    keyword: '',
    mode: PostReportMode.GRID,
    endDate: undefined, // NOTE: undefined is needed to set the default period if the filter is not set
    startDate: undefined
  }),
  sort: SearchFilterDefaults.sort(CampaignReportOrderField).default({}),
  state: z
    .object({
      post: z.boolean().optional(),
      total: z.boolean().optional(),
      influencer: z.boolean().optional()
    })
    .default({
      post: false,
      total: false,
      influencer: false
    }),
  ...SearchFilterDefaults.pageLimit(LIMIT_50)
});

export type PostReportSearchSchemaType = z.infer<typeof PostReportSearchSchema>;
export type PostReportFilterFormValues = z.infer<typeof PostReportFilterSchema>;
export type PostReportFilterFormKeys = UnionOfKeys<PostReportFilterFormValues>;
