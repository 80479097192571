import { z } from 'zod';
import { TikTokSpecialReportOrderField } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
import { PostReportFilterSchema } from '@/shared/molecules';
import { PostReportMode } from '@/shared/types';

export const TikTokPostReportSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(PostReportFilterSchema, {
    mode: PostReportMode.GRID,
    keyword: '',
    endDate: undefined, // NOTE: undefined is needed to set the default period if the filter is not set
    startDate: undefined
  }),
  sort: SearchFilterDefaults.sort(TikTokSpecialReportOrderField).default({})
});

export type TikTokPostReportSearchSchemaType = z.infer<typeof TikTokPostReportSearchSchema>;
