import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { AffiliateReportShowBy, MarketplaceAffiliateReportByInfluencerField } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';

export const MarketplaceAffiliateReportFilterSchema = z.object({
  mode: z.nativeEnum(AffiliateReportShowBy).optional(),
  endDate: z.string().optional(),
  startDate: z.string().optional()
});
export const MarketplaceAffiliateReportSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(MarketplaceAffiliateReportFilterSchema, {
    mode: AffiliateReportShowBy.DATE,
    endDate: undefined, // NOTE: undefined is needed to set the default period if the filter is not set
    startDate: undefined
  }),
  sort: SearchFilterDefaults.sort(MarketplaceAffiliateReportByInfluencerField).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type MarketplaceAffiliateReportSearchSchemaType = z.infer<typeof MarketplaceAffiliateReportSearchSchema>;
export type MarketplaceAffiliateReportFilterFormValues = z.infer<typeof MarketplaceAffiliateReportFilterSchema>;
export type MarketplaceAffiliateReportFilterFormKeys = UnionOfKeys<MarketplaceAffiliateReportFilterFormValues>;
